import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/redux-sagas",
  "date": "2016-03-27",
  "title": "REDUX & SAGAS",
  "author": "admin",
  "tags": ["development", "javascript", "react.js", "redux", "redux-saga"],
  "featuredImage": "feature.jpg",
  "excerpt": "In reality, for a real world application, just consuming an API end point and displaying the data is not going to cut it."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Let’s look at the potential issues we might face in a real world application. Every time you make a request to the server, the following happens.`}</p>
    <ul>
      <li parentName="ul">{`Handle usage tracking metrics.`}</li>
      <li parentName="ul">{`Display a loading screen until a response is delivered.`}</li>
      <li parentName="ul">{`Update caches`}</li>
      <li parentName="ul">{`Handle server errors`}</li>
      <li parentName="ul">{`Handle server timeouts`}</li>
    </ul>
    <p>{`This looks complicated than we thought it would be.`}</p>
    <h2>{`What are Sagas?`}</h2>
    <p>{`Sagas manage the flow control by removing the complexity and the mess, by organizing the actions.`}</p>
    <p>{`Sagas will convert your stories into `}<strong parentName="p">{`testable instructions`}</strong>{` including `}<strong parentName="p">{`promises`}</strong>{` and `}<strong parentName="p">{`callbacks`}</strong>{`.`}</p>
    <h2>{`Generator Functions`}</h2>
    <p>{`Sagas are written using generator functions – A function keyword followed by an asterix.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function *loadData() {
    ...
}
`}</code></pre>
    <p>{`Generators are functions which can be exited and later re-entered. Their context (variable bindings) will be saved across re-entrances.`}</p>
    <Message type="info" title="" content="Generators are functions which can be exited and later re-entered. Their context (variable bindings) will be saved across re-entrances. – **MDN**" mdxType="Message" />
    <p>{`The sagas are made up with a list of steps. We also have to connect the Saga middleware to the Redux store.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function *loadData() {
  yield 'step 1 - send request'
  yield 'step 2 - show waiting modal'
  yield 'step 3 - send analytics data'
  yield 'step 4 - update model'
  yield 'step 5 - hide waiting modal'
}
`}</code></pre>
    <p>{`In the redux-saga library, we get few helper functions which we can yield.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`call`}</strong>{` – an effect that will execute an async function eg:- Promise`}</li>
      <li parentName="ul"><strong parentName="li">{`put`}</strong>{` – an effect that will dispatch an action to the Redux Store`}</li>
    </ul>
    <p>{`Let’s rewrite this function using Redux.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`loadData() => {
    return dispatch => {
        dispatch({ type: 'FETCHING_DATA' });
        dispatch({ type: 'SHOW_WAITING_MODAL' });
        dispatch({ type: 'SEND_ANALYTICS_DATA' });
 
        fetch('/data').then(data => {
            dispatch({ type: 'FETCHED_DATA', payload: data });
            dispatch({ type: 'HIDE_WAITING_MODAL' });
        });
    }
}
`}</code></pre>
    <p>{`This would be a simple thunk action creator for implemented with redux. Problem with this is, in order to test, we need to create a mock to test the `}<inlineCode parentName="p">{`fetch`}</inlineCode>{`.`}</p>
    <p>{`Now let’s implement this using Sagas.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { call, put } from 'redux-saga';
 
function* loadData() {
  yield put({ type: 'FETCHING_DATA' });
  yield put({ type: 'SHOW_WAITING_MODAL' });
  yield put({ type: 'SEND_ANALYTICS_DATA' });
 
  const data = yield call(fetch, '/data');
  yield put({ type: 'FETCHED_DATA', payload: data });
  yield put({ type: 'HIDE_WAITING_MODAL' });
}
`}</code></pre>
    <p>{`Let’s break this down with plain English:`}</p>
    <ul>
      <li parentName="ul">{`Dispatch a FETCHING_DATA action through Redux.`}</li>
      <li parentName="ul">{`Dispatch a SHOW_WAITING_MODAL action through Redux to the view for showing the modal.`}</li>
      <li parentName="ul">{`Dispatch a SEND_ANALYTICS_DATA action through Redux.`}</li>
      <li parentName="ul">{`Call the API for data and get back a response.`}</li>
      <li parentName="ul">{`Dispatch a FETCHED_DATA action through Redux to indicate the data has been received.`}</li>
      <li parentName="ul">{`Dispatch a HIDE_WAITING_MODAL action through Redux to indicate the view to hide the modal.`}</li>
    </ul>
    <p>{`This is nice and concise and describes a flow that’s easy to understand.`}</p>
    <h2>{`Testing Sagas`}</h2>
    <p>{`Now let’s see how we can test this saga. It is actually quite easy.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { call, put } from 'redux-saga';
 
const saga = loadData();
const data = [{ message: 'text', done: false }];
saga.next();
 
expect(saga.next().value).toEqual(
  put({ type: 'FETCHING_DATA' })
);

expect(saga.next().value).toEqual(
  call(fetch, '/data')
);

expect(saga.next(data).value).toEqual(
  put({ type: 'FETCHED_DATA', payload: data })
);
`}</code></pre>
    <h2>{`Trigger a Saga`}</h2>
    <p>{`Let’s look at how we can bind our saga to the Redux application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createStore, applyMiddleware } from 'redux';
import sagaMiddleware from 'redux-saga';
 
const createStoreWithSaga = applyMiddleware(
  sagaMiddleware([loadData])
)(createStore);
 
const store = createStoreWithSaga(reducer, initialState);
`}</code></pre>
    <h2>{`Combining Sagas`}</h2>
    <p>{`Composing sagas is easy because saga is an `}<inlineCode parentName="p">{`effect`}</inlineCode>{`(take, fork etc.).`}</p>
    <p>{`If we were to trigger our saga each time a special action is dispatched to the store, rather than at startup, we can change our code like below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { fork, take } from 'redux-saga';
 
function* loadData() {
    yield put({ type: 'FETCHING_DATA' });
    const todos = yield call(fetch, '/data');
    yield put({ type: 'FETCHED_DATA', payload: todos });
}
 
function* watchData() {
     while (yield take('FETCH_DATA')) {
         yield fork(loadTodos);
     }
}
 
// update our root saga
const createStoreWithSaga = applyMiddleware(
  sagaMiddleware([watchData])
)(createStore);
`}</code></pre>
    <p>{`We are using two effects provided by redux-saga:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`take`}</strong>{` – an effect that waits for a redux action to be dispatched`}</li>
      <li parentName="ul"><strong parentName="li">{`fork`}</strong>{` – an effect that will trigger another effect but does not wait for the end of sub effect to continue`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      